<template>
  <v-container
    fluid
    fill-height
    class="down-top-padding revision-editor-container"
  >
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              v-on:menuAction="menuAction"
              v-bind:actionMenu="actionMenu"
              v-bind:heading="heading"
              v-bind:enableAction="revisionStatus === 'pending'"
            />
            <header-right
              slot="right-toolbar"
              @reloadThis="reloadThis"
              :enableAction="revisionStatus === 'pending'"
              v-bind:singleRevision="singleRevision"
            />
            <v-card-text
              v-if="!singleRevision && !axiosLoading && !networkError"
            >
              <p class="align-center center-align">
                {{ $t("text.revision-detail-empty") }}
              </p>
            </v-card-text>
            <v-card-text
              v-if="networkError || (axiosLoading && !singleRevision)"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-three-line, image, actions"
              >
              </v-skeleton-loader>
            </v-card-text>
            <v-card-text class="pa-0" v-if="singleRevision">
              <v-col cols="12" class="pa-0">
                <v-tabs color="blue" class="px-4" v-model="myTab">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab
                    href="#tab-1"
                    v-if="
                      singleRevision &&
                      singleRevision.memo.memo_status_id != 100
                    "
                  >
                    <v-icon class="mr-2">{{ iconEdit }}</v-icon
                    >{{ $t("tab.revision") }}
                  </v-tab>
                  <v-tab
                    href="#tab-1"
                    v-if="
                      singleRevision &&
                      singleRevision.memo.memo_status_id == 100
                    "
                  >
                    <v-icon class="mr-2">{{ iconMailOpen }}</v-icon
                    >{{ $t("tab.message") }}
                  </v-tab>
                  <v-tab href="#tab-2">
                    <v-icon class="mr-2">{{ iconHistory }}</v-icon
                    >{{ $t("tab.history") }}
                  </v-tab>
                  <v-tab href="#tab-3" v-if="singleRevision.memo_ref">
                    <v-icon class="mr-2">{{ iconInbox }}</v-icon
                    >{{ $t("tab.reference") }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="myTab" touchless>
                  <v-tab-item value="tab-1">
                    <v-container
                      v-if="
                        singleRevision.memo.tipe === 'sppd' &&
                        parseInt(singleRevision.memo.memo_status_id) !== 100
                      "
                      class="auto--width"
                    >
                      <v-card-text height="100%">
                        <v-row class="mt-3" v-if="false">
                          <v-col cols="12" class="pt-0 pb-xs-0 mb-3">
                            <p>
                              <span style="font-weight: 600; font-size: 1.1rem"
                                >Serapan Biaya SPPD Divisi :</span
                              >
                              <br />
                              RKAP :<span
                                style="
                                  margin-right: 0.5rem;
                                  font-size: 1.1rem;
                                  color: #0950d7;
                                "
                                >Rp.1.200.000.000,</span
                              >Realisasi :<span
                                style="font-size: 1.1rem; color: #d1023a"
                                >Rp.520.000.000</span
                              >
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-3">
                          <v-col
                            cols="12"
                            sm="12"
                            lg="4"
                            class="pt-0 pb-xs-0 mb-3"
                          >
                            <v-autocomplete
                              :data-hint="
                                $t('introjs.this is jenis perjalanan selector')
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                              :disabled="!userCanEdit"
                              v-model="form.jenis_perjalanan"
                              v-bind:items="jenisPerjalanan"
                              v-bind:menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                              }"
                              auto-select-first
                              hide-details="true"
                              item-text="title"
                              item-value="value"
                              clearable
                              :prepend-icon="iconMenu"
                              label="Jenis Perjalanan"
                              placeholder="Jenis Perjalanan"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.title }}
                              </template>
                            </v-autocomplete>
                            <div
                              class="error--badge-container pulse--animation"
                              v-if="
                                $v.form.jenis_perjalanan.$error &&
                                !$v.form.jenis_perjalanan.required
                              "
                            >
                              <span class="error--badge">
                                {{
                                  $t(
                                    "error.the type of travel must be selected"
                                  )
                                }}
                              </span>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            lg="8"
                            class="pt-0 pb-xs-0 mb-3"
                          >
                            <v-autocomplete
                              :data-hint="
                                $t('introjs.this is initiator selector')
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                              :disabled="!userCanEdit"
                              clearable
                              return-object
                              v-bind:menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                              }"
                              id="inisiatorInput"
                              v-model="form.bagian_inisiator"
                              :items="listBagian"
                              hide-no-data
                              hide-selected
                              auto-select-first
                              hide-details="true"
                              item-text="nama_bagian"
                              item-value="inisial_bagian"
                              :prepend-icon="iconMenu"
                              :label="$t('label.initiator department')"
                              :placeholder="$t('label.initiator department')"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.nama_bagian }}
                              </template>
                            </v-autocomplete>
                            <div
                              class="error--badge-container pulse--animation"
                              v-if="
                                $v.form.bagian_inisiator.$error &&
                                !$v.form.bagian_inisiator.required
                              "
                            >
                              <span class="error--badge">
                                {{
                                  $t(
                                    "error.initiator department must be selected"
                                  )
                                }}
                              </span>
                            </div>
                          </v-col>
                        </v-row>

                        <!-- Tujuan Dinas -->

                        <v-row class="mt-3">
                          <v-col cols="12" md="5" class="pt-0 pb-xs-0">
                            <v-text-field
                              :disabled="!userCanEdit"
                              :prepend-icon="iconEdit"
                              v-model="form.tujuan_dinas_detail"
                              :label="$t('label.official purpose')"
                              hide-details="auto"
                            ></v-text-field>
                            <div
                              v-if="$v.form.tujuan_dinas_detail.$error"
                              class="error--badge-container -margin-top pulse--animation"
                              style="margin-top: 0.1rem"
                            >
                              <span
                                v-if="!$v.form.tujuan_dinas_detail.required"
                                class="error--badge"
                              >
                                {{
                                  $t("error.official purpose must be filled")
                                }}
                              </span>
                              <span
                                v-if="!$v.form.tujuan_dinas_detail.maxLength"
                                class="error--badge"
                              >
                                {{ $t("error.max length is 100") }}
                              </span>
                              <span
                                v-if="!$v.form.tujuan_dinas_detail.minLength"
                                class="error--badge"
                              >
                                {{ $t("error.min length is 10") }}
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" class="pt-0 pb-xs-0">
                            <v-autocomplete
                              v-if="form.jenis_perjalanan !== 'luar_negeri'"
                              :data-hint="
                                $t('introjs.this is destination city selector')
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                              :prepend-icon="iconMapSearch"
                              :disabled="!userCanEdit"
                              v-model="selectedLokasi"
                              :items="itemsLokasi"
                              :loading="lokasiLoading"
                              :search-input.sync="qSearchLokasi"
                              hide-no-data
                              hide-selected
                              item-text="Description"
                              item-value="API"
                              label="Kota Provinsi"
                              placeholder="Ketik Nama Kota"
                              clearable
                              return-object
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.nama_kota }}
                              </template>
                            </v-autocomplete>
                            <div
                              v-if="
                                $v.form.nama_provinsi.$error &&
                                !$v.form.nama_provinsi.required
                              "
                              class="error--badge-container -margin-top pulse--animation"
                            >
                              <span class="error--badge">
                                {{
                                  $t(
                                    "error.you have to choose the name of the provincial city"
                                  )
                                }}
                              </span>
                            </div>
                          </v-col>
                          <v-col
                            v-if="selectedLokasi"
                            cols="12"
                            md="3"
                            class="auto--pl pt-0 pb-xs-0"
                          >
                            <p style="display: block">
                              <span
                                style="
                                  font-size: 11px;
                                  display: block;
                                  margin-bottom: 0.2rem;
                                "
                                >Nama Provinsi</span
                              >
                              {{ selectedLokasi?.nama_provinsi }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="pt-0 pb-xs-0">
                            <v-textarea
                              :disabled="!userCanEdit"
                              :prepend-icon="iconEdit"
                              clearable
                              auto-grow
                              rows="2"
                              label="Keperluan Dinas"
                              v-model="form.keperluan_dinas"
                            ></v-textarea>
                            <div
                              class="error--badge-container -margin-top pulse--animation"
                            >
                              <span
                                class="error--badge"
                                v-if="
                                  $v.form.keperluan_dinas.$error &&
                                  !$v.form.keperluan_dinas.required
                                "
                              >
                                {{ $t("error.fill in official requirements") }}
                              </span>
                              <span
                                class="error--badge"
                                v-if="
                                  $v.form.keperluan_dinas.$error &&
                                  !$v.form.keperluan_dinas.minLength
                                "
                              >
                                {{ $t("error.min length is 10 chars") }}
                              </span>
                              <span
                                class="error--badge"
                                v-if="
                                  $v.form.keperluan_dinas.$error &&
                                  !$v.form.keperluan_dinas.maxLength
                                "
                              >
                                {{ $t("error.max length is 200 chars") }}
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" lg="3" class="pt-0 pb-xs-0">
                            <v-menu
                              v-model="menuTglBerangkat"
                              :close-on-content-click="true"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="!userCanEdit"
                                  v-model="form.tanggal_berangkat"
                                  :label="$t('label.departure date')"
                                  :prepend-icon="iconTgl"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="form.tanggal_berangkat"
                                @input="menuTglBerangkat = false"
                              ></v-date-picker>
                            </v-menu>
                            <div
                              v-if="
                                $v.form.tanggal_berangkat.$error &&
                                !$v.form.tanggal_berangkat.required
                              "
                              class="error--badge-container -margin-top pulse--animation mt-1"
                            >
                              <span class="error--badge" v-if="true">
                                {{ $t("error.select departure date") }}
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="3" class="pt-0 pb-xs-0">
                            <v-menu
                              v-model="menuTglKembali"
                              :close-on-content-click="true"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="!userCanEdit"
                                  v-model="form.tanggal_kembali"
                                  :label="$t('label.arrival date')"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="form.tanggal_kembali"
                                @input="menuTglKembali = false"
                              ></v-date-picker>
                            </v-menu>
                            <div
                              v-if="
                                $v.form.tanggal_kembali.$error &&
                                !$v.form.tanggal_kembali.required
                              "
                              class="error--badge-container -margin-top pulse--animation mt-1"
                            >
                              <span class="error--badge" v-if="true">
                                {{ $t("error.select departure date") }}
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="6" class="pt-0 pb-xs-0 auto--pl">
                            <v-combobox
                              :disabled="!userCanEdit"
                              :prepend-icon="iconPlaneCar"
                              clearable
                              hide-selected
                              :label="$t('label.transportation')"
                              v-model="form.transport"
                              :items="transportList"
                              :data-hint="
                                $t('introjs.this is transport selector')
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                            ></v-combobox>
                            <div
                              class="error--badge-container -margin-top pulse--animation mt-1"
                              style="padding-left: 0rem"
                              v-if="
                                $v.form.transport.$error &&
                                !$v.form.transport.required
                              "
                            >
                              <span class="error--badge">
                                {{ $t("error.select transportation") }}
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                        <div
                          v-if="!dateIsValid"
                          class="error--badge-container -margin-top pulse--animation mb-2"
                          style="width: 50%"
                        >
                          <span class="error--badge" v-if="true">
                            {{
                              $t(
                                "error.the return date must be set after the departure date"
                              )
                            }}
                          </span>
                        </div>
                        <v-row>
                          <v-col cols="12" md="6" class="pt-0 pb-xs-0">
                            <v-autocomplete
                              :data-hint="
                                $t('introjs.this is on site transport selector')
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                              :disabled="!userCanEdit"
                              v-model="form.transport_lokasi_dinas"
                              v-bind:items="transportDiTujuan"
                              v-bind:menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                              }"
                              auto-select-first
                              hide-details="true"
                              item-text="title"
                              item-value="value"
                              clearable
                              :prepend-icon="iconCar"
                              label="Kendaraan Di Lokasi Dinas"
                              placeholder="Kendaraan Di Lokasi Dinas"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.title }}
                              </template>
                            </v-autocomplete>
                            <div
                              v-if="
                                $v.form.transport_lokasi_dinas.$error &&
                                !$v.form.transport_lokasi_dinas.required
                              "
                              class="block error--badge-container -margin-top pulse--animation mb-2 mt-2"
                            >
                              <span class="error--badge mb-1">
                                {{ $t("error.select on site transport") }}
                              </span>
                            </div>
                            <div
                              v-if="!canRentACar"
                              class="error--badge-container -margin-top pulse--animation mb-2 mt-2"
                            >
                              <span class="error--badge mb-1">
                                {{
                                  $t(
                                    "error.the minimum group departing is two pax"
                                  )
                                }}
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="6" class="pt-0 pb-xs-0">
                            <v-file-input
                              v-if="!serverAttachment"
                              :disabled="!userCanEdit"
                              accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                      , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                      application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                              v-model="form.attachment"
                              :label="$t('label.supporting file')"
                              show-size
                              truncate-length="15"
                            ></v-file-input>
                            <div v-else>
                              <span style="display: block; font-size: 0.87em">
                                {{ $t("label.attachment") }}
                              </span>
                              <v-icon style="transform: rotate(90deg)">{{
                                iconAttachment
                              }}</v-icon>
                              <span style="margin-left: 0.5rem"
                                >{{ this.serverAttachment?.file_name }} ({{
                                  this.serverAttachment?.file_size | formatSize
                                }})</span
                              >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="userCanEdit"
                                    id="btn-sppd-attachment-delete"
                                    icon
                                    @click="confirmDeleteAttachment()"
                                    v-on="on"
                                    color="red"
                                    v-bind="attrs"
                                  >
                                    <v-icon>{{ iconDelete }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{
                                  $t("tooltip.remove attachment")
                                }}</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    id="btn-sppd-download-attachment"
                                    icon
                                    @click="
                                      downloadAttachment('view', {
                                        memo_id: serverAttachment.memo_id_hash,
                                        id_hash: serverAttachment.id_hash,
                                        file_name: serverAttachment.file_name,
                                        file_type: serverAttachment.file_type,
                                      })
                                    "
                                    v-on="on"
                                    color="blue"
                                    v-bind="attrs"
                                  >
                                    <v-icon>{{ iconEye }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("tooltip.show attachment") }}</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    id="btn-sppd-download-attachment"
                                    icon
                                    @click="
                                      downloadAttachment('download', {
                                        memo_id: serverAttachment.memo_id_hash,
                                        id_hash: serverAttachment.id_hash,
                                        file_name: serverAttachment.file_name,
                                      })
                                    "
                                    v-on="on"
                                    color="blue"
                                    v-bind="attrs"
                                  >
                                    <v-icon>{{ iconDownload }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{
                                  $t("tooltip.download attachment")
                                }}</span>
                              </v-tooltip>
                            </div>
                          </v-col>
                        </v-row>
                        <v-spacer class="border-b"></v-spacer>
                        <v-row>
                          <v-col cols="12" class="pt-0 pb-xs-0 mt-2 mb-2">
                            <label
                              for="chkCreateSendiri"
                              style="padding-left: 0.3rem"
                              >Nama Karyawan (Diri Sendiri/Kepala
                              Rombongan)</label
                            >
                          </v-col>
                        </v-row>
                        <v-row class="mt-3" v-if="!sendiri">
                          <v-col cols="12" sm="12" md="6" class="pt-0 pb-xs-0">
                            <v-autocomplete
                              :disabled="!userCanEdit"
                              :data-hint="$t('introjs.this is pic selector')"
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                              v-bind:search-input.sync="qSearchKaryawan"
                              @change="qSearchKaryawan = ''"
                              v-model="selectedPic"
                              :items="karyawanDivisi"
                              hide-no-data
                              hide-selected
                              item-text="nama"
                              item-value="nik_sap"
                              label="Nama Karyawan (Kepala Rombongan)"
                              placeholder="Ketik NIK, Nama atau Jabatan"
                              :prepend-icon="iconSearch"
                              clearable
                              return-object
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.nama }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            v-if="selectedPic"
                            cols="12"
                            sm="6"
                            md="2"
                            class="auto--pl pt-0 pb-xs-0"
                          >
                            <p style="display: block">
                              <span
                                style="
                                  font-size: 11px;
                                  display: block;
                                  margin-bottom: 0.2rem;
                                "
                                >NIK SAP</span
                              >
                              {{ selectedPic.nik_sap }}
                            </p>
                          </v-col>
                          <v-col
                            v-if="selectedPic"
                            cols="12"
                            sm="6"
                            md="4"
                            class="auto--pl pt-0 pb-xs-0"
                          >
                            <p style="display: block">
                              <span
                                style="
                                  font-size: 11px;
                                  display: block;
                                  margin-bottom: 0.2rem;
                                "
                                >Jabatan/Bagian</span
                              >
                              {{ selectedPic?.nama_jabatan }}
                              <br />
                              {{ selectedPic?.nama_bagian }}
                              <br />
                              <strong>({{ selectedPic?.grade_sppd }})</strong>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row style="margin-top: 0.1rem">
                          <v-col cols="12">
                            <div
                              class="error--badge-container -margin-top pulse--animation"
                            >
                              <span
                                class="error--badge"
                                v-if="
                                  $v.form.selected_pic.$error &&
                                  !$v.form.selected_pic.required
                                "
                              >
                                {{ $t("error.you must select one employee") }}
                              </span>
                              <span class="error--badge" v-if="nikIsDuplicate">
                                {{ $t("error.nik is duplicate") }}
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-xs-0">
                            <v-checkbox
                              :disabled="!userCanEdit"
                              :model-value="form.rombongan_organik"
                              v-model="form.rombongan_organik"
                              label="Rombongan Karyawan PTPN IV"
                              color="primary"
                              hide-details
                              aria-checked="false"
                              v-bind:data-hint="
                                $t(
                                  'introjs.this checkbox for toggle ptpn4 member'
                                )
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <div
                          class="error--badge-container -margin-top pulse--animation mt-1"
                        >
                          <span class="error--badge mb-1" v-if="nikIsDuplicate">
                            {{ $t("error.nik is duplicate") }}
                          </span>
                          <span class="error--badge" v-if="memberIsDuplicate">
                            {{ $t("error.member is duplicate") }}
                          </span>
                        </div>
                        <div
                          class="error--badge-container -margin-top pulse--animation mt-1"
                        >
                          <span class="error--badge" v-if="memberIsUser">
                            {{ $t("error.user cannot be member") }}
                          </span>
                        </div>
                        <div v-if="form.rombongan_organik">
                          <v-row
                            class="mt-3 relative"
                            v-for="(rombongan, index) in rombonganOrganiks"
                            :key="index"
                          >
                            <v-col
                              cols="12"
                              sm="12"
                              md="5"
                              class="pt-0 pb-xs-0"
                            >
                              <v-autocomplete
                                :data-hint="
                                  $t('introjs.this is employee member selector')
                                "
                                data-hintposition="middle-middle"
                                data-position="bottom-right-aligned"
                                :disabled="!userCanEdit"
                                v-bind:search-input.sync="qSearchKaryawan"
                                :id="'rombongan_' + index"
                                @change="qSearchKaryawan = ''"
                                v-model="rombongan.selected"
                                :items="karyawanRombongan"
                                hide-no-data
                                hide-selected
                                item-text="nama"
                                item-value="nik_sap"
                                label="Karyawan (Peserta Rombongan)"
                                placeholder="Ketik NIK, Nama atau Jabatan"
                                :prepend-icon="iconSearch"
                                clearable
                                return-object
                              >
                                <template slot="selection" slot-scope="data">
                                  {{ data.item.nama }}
                                </template>
                              </v-autocomplete>
                              <v-tooltip
                                bottom
                                v-if="
                                  !$vuetify.breakpoint.smAndDown && userCanEdit
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    tabindex="-1"
                                    v-bind:data-hint="
                                      $t(
                                        'introjs.this button is to remove member'
                                      )
                                    "
                                    data-hintposition="middle-middle"
                                    data-position="bottom-right-aligned"
                                    v-bind:loading="axiosProcessing"
                                    v-bind:disabled="axiosProcessing"
                                    color="blue-grey"
                                    class="ml-5 px-0 white--text rombongan--button"
                                    @click="removeRombongan('x', index)"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                    <v-icon dark>
                                      {{ iconDelete }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("tooltip.remove-member") }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              v-if="
                                rombongan.selected && rombongan.selected.nama
                              "
                              cols="12"
                              sm="6"
                              md="2"
                              class="auto--pl pt-0 pb-xs-0"
                            >
                              <p style="display: block">
                                <span
                                  style="
                                    font-size: 11px;
                                    display: block;
                                    margin-bottom: 0.2rem;
                                  "
                                  >NIK SAP</span
                                >
                                {{ rombongan.selected?.nik_sap }}
                              </p>
                            </v-col>
                            <v-col
                              v-if="rombongan.selected"
                              cols="12"
                              sm="6"
                              md="5"
                              class="auto--pl pt-0 pb-xs-0"
                            >
                              <p style="display: block">
                                <span
                                  style="
                                    font-size: 11px;
                                    display: block;
                                    margin-bottom: 0.2rem;
                                  "
                                  >Jabatan/Bagian</span
                                >
                                {{ rombongan.selected?.nama_jabatan }}
                                <br />
                                {{ rombongan.selected?.nama_bagian }}
                                <br />
                                <strong>({{ rombongan.selected?.grade_sppd }})</strong>
                              </p>
                            </v-col>
                          </v-row>

                          <v-tooltip
                            bottom
                            v-if="!$vuetify.breakpoint.smAndDown && userCanEdit"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind:data-hint="
                                  $t('introjs.this button is to add member')
                                "
                                data-hintposition="middle-middle"
                                data-position="bottom-right-aligned"
                                v-bind:loading="axiosProcessing"
                                v-bind:disabled="axiosProcessing"
                                color="blue-grey"
                                class="mb-3 ml-8 white--text px-0"
                                @click="addRombongan('x')"
                                v-on="on"
                                v-bind="attrs"
                              >
                                <v-icon dark>
                                  {{ iconAdd }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("tooltip.add-member") }}</span>
                          </v-tooltip>
                        </div>
                        <v-spacer class="border-b"></v-spacer>
                        <v-row>
                          <v-col cols="12" class="d-flex pt-0 pb-xs-0 flex">
                            <v-checkbox
                              :disabled="!userCanEdit"
                              :model-value="form.rombongan_non_organik"
                              v-model="form.rombongan_non_organik"
                              label="Rombongan Non Karyawan"
                              color="primary"
                              hide-details
                              aria-checked="false"
                              v-bind:data-hint="
                                $t(
                                  'introjs.this checkbox for toggle non ptpn4 member'
                                )
                              "
                              data-hintposition="middle-middle"
                              data-position="bottom-right-aligned"
                            ></v-checkbox>
                            <span
                              style="padding-top: 1.6rem; padding-left: 0.5rem"
                              >(Atas Nama Kepala rombongan)</span
                            >
                          </v-col>
                        </v-row>
                        <div v-if="form.rombongan_non_organik">
                          <v-row
                            class="mt-3 relative"
                            v-for="(rombongan, index) in rombonganNonOrganiks"
                            :key="index"
                          >
                            <v-col cols="12" md="5" class="mt-3 pt-0 pb-xs-0">
                              <v-text-field
                                :prepend-icon="iconEdit"
                                :disabled="!userCanEdit"
                                label="Nama Peserta"
                                v-model="rombongan.nama"
                              ></v-text-field>
                              <v-tooltip
                                bottom
                                v-if="
                                  !$vuetify.breakpoint.smAndDown && userCanEdit
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    tabindex="-1"
                                    v-bind:data-hint="
                                      $t(
                                        'introjs.this button is to remove member'
                                      )
                                    "
                                    data-hintposition="middle-middle"
                                    data-position="bottom-right-aligned"
                                    v-bind:loading="axiosProcessing"
                                    v-bind:disabled="axiosProcessing"
                                    color="blue-grey"
                                    class="ml-5 px-0 white--text rombongan--button"
                                    @click="
                                      removeRombongan('non_organik', index)
                                    "
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                    <v-icon dark>
                                      {{ iconDelete }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("tooltip.remove-member") }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="12"
                              md="3"
                              class="auto--pl mt-3 pt-0 pb-xs-0"
                            >
                              <v-combobox
                                :disabled="!userCanEdit"
                                clearable
                                hide-selected
                                label="Grade Karyawan"
                                v-model="rombongan.grade"
                                :items="gradeList"
                              ></v-combobox>
                            </v-col>
                            <v-col
                              cols="12"
                              md="3"
                              class="auto--pl mt-3 pt-0 pb-xs-0"
                            >
                              <v-combobox
                                :disabled="!userCanEdit"
                                clearable
                                hide-selected
                                label="Status Karyawan"
                                v-model="rombongan.status_karyawan"
                                :items="statusKaryawan"
                              ></v-combobox>
                            </v-col>
                          </v-row>
                          <v-tooltip
                            bottom
                            v-if="!$vuetify.breakpoint.smAndDown && userCanEdit"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind:data-hint="
                                  $t('introjs.this button is to add member')
                                "
                                data-hintposition="middle-middle"
                                data-position="bottom-right-aligned"
                                v-bind:loading="axiosProcessing"
                                v-bind:disabled="axiosProcessing"
                                color="blue-grey"
                                class="mb-3 ml-8 white--text px-0"
                                @click="addRombongan('non_organik')"
                                v-on="on"
                                v-bind="attrs"
                              >
                                <v-icon dark>
                                  {{ iconAdd }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("tooltip.add-member") }}</span>
                          </v-tooltip>
                        </div>
                      </v-card-text>
                    </v-container>
                    <v-container fluid v-else>
                      <v-text-field
                        :data-hint="$t('introjs.this-is-memo-subject')"
                        class="my-4 memo__revision--title"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        v-model="hal"
                        hide-details="true"
                        maxlength="250"
                        :hint="$t('tooltip.min-max-char-for-memo-subject')"
                        :label="$t('label.memo-subject')"
                        :disabled="!userCanEdit"
                      ></v-text-field>
                      <v-text-field
                        v-if="isSuratMasuk"
                        v-model="lembagaPengirim"
                      >
                      </v-text-field>

                      <froala-editor
                        v-bind:data-hint="$t('introjs.this-is-memo-message')"
                        v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        v-bind:oldEditorData="isi"
                        v-on:editorData="changeEditorData"
                        v-on:froalaIsWorking="froalaIsWorking"
                        v-bind:editorDisabled="!userCanEdit"
                      ></froala-editor>
                      <v-col cols="12 table-responsive-container">
                        <div
                          class="attachment-list mt-4 table-responsive"
                          v-if="
                            (attachments && attachments.length > 0) ||
                            (currAttachments && currAttachments.length > 0)
                          "
                        >
                          <p
                            class="mb-1 text--avore-grey"
                            v-html="$t('text.new-attachment-list')"
                          ></p>
                          <ul>
                            <li
                              v-for="(item, index) in currAttachments"
                              v-bind:key="index"
                              v-bind:value="item.id"
                              class="file--list"
                            >
                              <a href="javascript:;" class="server--file">
                                <span>{{ index + 1 }}.</span>
                                {{ item.file_name }} -
                                {{ item.file_size | formatSize }}

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="ml-2"
                                      color="blue"
                                      v-on:click="
                                        downloadAttachment('view', item)
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconEye }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("tooltip.show attachment")
                                  }}</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      color="blue"
                                      v-on:click="
                                        downloadAttachment('download', item)
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDownload }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("tooltip.download-this-file")
                                  }}</span>
                                </v-tooltip>

                                <v-tooltip
                                  bottom
                                  v-if="
                                    !['PJP', 'PJP_KANPUS', 'DIREKTUR'].includes(
                                      currentUserType
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="revisionStatus === 'pending'"
                                      icon
                                      color="red"
                                      v-on:click="
                                        confirmDeleteAttachment(item, index)
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDelete }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("tooltip.delete-this-file")
                                  }}</span>
                                </v-tooltip>
                              </a>
                            </li>
                            <li
                              v-for="(item, idx) in attachments"
                              v-bind:key="item.id"
                              v-bind:value="item.id"
                              class="file--list"
                            >
                              <a href="javascript:;">
                                <span
                                  >{{ idx + 1 + currAttachments.length }}.</span
                                >
                                {{ item.name }} - {{ item.size | formatSize }}
                                <span v-if="item.error">{{ item.error }}</span>
                                <span v-else-if="item.success">success</span>
                                <span v-else-if="item.active">active</span>
                                <span v-else></span>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="ml-2"
                                      color="red"
                                      v-on:click="deleteAttachment(item)"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDelete }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("tooltip.delete-this-file")
                                  }}</span>
                                </v-tooltip>
                              </a>
                            </li>
                          </ul>
                          <div
                            v-if="
                              singleRevision.memo_attachments &&
                              singleRevision.memo_attachments.length > 1
                            "
                            style="
                              padding: 0.5rem 0rem;
                              display: flex;
                              flex-direction: row;
                              justify-content: flex-start;
                              align-items: center;
                            "
                          >
                            <p style="margin-bottom: 0px; padding-left: 1rem">
                              {{ $t("text.download all as zip") }}
                            </p>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  class="ml-2"
                                  color="blue"
                                  v-on:click="downloadAllAsZip()"
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <v-icon>{{ iconZip }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{
                                $t("tooltip.download all as zip")
                              }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                    </v-container>
                    <v-row
                      v-if="!networkError || (!axiosLoading && singleRevision)"
                    >
                      <v-col
                        cols="12"
                        class="d-flex justify-end"
                        v-if="
                          currentUserType != 'KRANI' &&
                          revisionStatus === 'pending'
                        "
                      >
                        <v-tooltip
                          top
                          v-if="
                            !['DIREKTUR'].includes(currentUserType) &&
                            singleRevision.memo.tipe !== 'sppd'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <file-upload
                              input-id="edit-revision"
                              v-on="on"
                              v-bind="attrs"
                              class="att-button v-size--default ma-2 v-btn v-btn--outlined v-size--default blue--text text--lighten-2"
                              post-action="/upload/post"
                              extensions="jpg,jpeg,png,pdf,doc,docx,xls,xlsx,zip,rar,ppt,pptx"
                              accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                              v-bind:multiple="true"
                              v-bind:size="1"
                              v-bind:active="true"
                              v-bind:maximum="3"
                              v-model="attachments"
                              v-on:input-filter="inputFilter"
                              v-on:input-file="inputFile"
                              v-bind:title="$t('tooltip.add-attachments')"
                              ref="upload"
                            >
                              <v-icon class="blue--text text--lighten-2">{{
                                iconAttachment
                              }}</v-icon>
                            </file-upload>
                          </template>
                          <span>{{ $t("tooltip.add-attachments") }}</span>
                        </v-tooltip>

                        <v-menu top left>
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  data-hintposition="middle-middle"
                                  class="ma-2"
                                  outlined
                                  color="blue lighten-2"
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon class="mr-2">{{ iconCog }}</v-icon>
                                  {{ $t("button.actions") }}
                                </v-btn>
                              </template>
                              <span>{{ $t("tooltip.select-action") }}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, i) in actionMenu"
                              :key="i"
                              @click="menuAction({ command: item.command })"
                            >
                              <mdicon
                                :name="item.icon_name"
                                class="v-icon mr-2"
                              />
                              <v-list-item-title>{{
                                $t(item.title)
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <history-detail></history-detail>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item value="tab-3" v-if="singleRevision.memo_ref">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <reference-detail
                            v-bind:singleMemo="singleRevision"
                          ></reference-detail>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-card-text>
            <hr class="card-footer" />
            <v-card
              elevation="0"
              class="mt-2 d-flex justify-end no-box-shadow no-border v-sheet--outlined"
            >
              <v-tooltip bottom v-if="!userIsPjpDirSekretaris && sentOrRevoked">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="ma-2"
                    outlined
                    color="warning"
                    @click="copyAsNewDraft"
                  >
                    <v-icon>{{ iconDuplicate }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.copy-as-new-draft") }}</span>
              </v-tooltip>
            </v-card>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>

    <peer-selector
      v-bind:showPeerDialog="showPeerDialog"
      v-on:showHidePeerDialog="showHidePeerDialog"
      v-on:peerSelected="peerSelected"
    ></peer-selector>

    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
      v-bind:submitOptions="submitOptions"
    ></insert-comment>

    <insert-passphrase
      v-bind:showPassPhraseDialog="showPassPhraseDialog"
      v-on:showHidePassPhraseDialog="showHidePassPhraseDialog"
      v-on:passPhraseFilled="passPhraseFilled"
      v-bind:submitOptions="submitOptions"
    ></insert-passphrase>

    <vue-html2pdf
      v-bind:show-layout="controlValue.showLayout"
      v-bind:float-layout="controlValue.floatLayout"
      v-bind:enable-download="controlValue.enableDownload"
      v-bind:preview-modal="controlValue.previewModal"
      v-bind:filename="controlValue.filename"
      v-bind:paginate-elements-by-height="controlValue.paginateElementsByHeight"
      v-bind:pdf-quality="controlValue.pdfQuality"
      v-bind:pdf-format="controlValue.pdfFormat"
      v-bind:pdf-orientation="controlValue.pdfOrientation"
      v-bind:pdf-content-width="controlValue.pdfContentWidth"
      v-bind:manual-pagination="controlValue.manualPagination"
      v-bind:html-to-pdf-options="htmlToPdfOptions"
      v-on:progress="onProgress($event)"
      v-on:startPagination="startPagination()"
      v-on:hasPaginated="hasPaginated()"
      v-on:beforeDownload="beforeDownload($event)"
      v-on:hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <memo-print-preview
        v-on:domRendered="domRendered()"
        v-bind:singleMemo="singleRevision"
        v-bind:recipientSide="false"
        slot="pdf-content"
      />
    </vue-html2pdf>

    <memo-validation-modal :singleMemo="singleRevision"></memo-validation-modal>

    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import HistoryDetail from "./HistoryDetail";
import ReferenceDetail from "./ReferenceDetail";
import peerSelector from "@/components/commonComponents/PeerSelector.vue";
import insertComment from "@/components/commonComponents/InsertComment.vue";
import insertPassphrase from "@/components/commonComponents/InsertPassphrase.vue";
import { isIntroActive } from "@/constants/config";
import dayjs from "dayjs";
import froalaEditor from "@/components/commonComponents/FroalaEditor";
import "intro.js/minified/introjs.min.css";
import VueUploadComponent from "vue-upload-component";
import { http } from "@/http/http";
import MemoPrintPreview from "@/components/commonComponents/MemoPrintPreview.vue";
import memoValidationModal from "@/components/commonComponents/MemoValidationModal";
import VueHtml2pdf from "vue-html2pdf";
import { mapFields } from "vuex-map-fields";
import { validationMixin } from "vuelidate";
import debounce from "lodash/debounce";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiPen,
  mdiHistory,
  mdiContentSaveOutline,
  mdiDeleteAlertOutline,
  mdiCloudDownloadOutline,
  mdiEmailOpenOutline,
  mdiSend,
  mdiHelpCircle,
  mdiCog,
  mdiStepBackward2,
  mdiEmailArrowRightOutline,
  mdiContentDuplicate,
  mdiElevatorUp,
  mdiZipBoxOutline,
  mdiMenu,
  mdiCalendar,
  mdiClose,
  mdiAccount,
  mdiCheck,
  mdiMagnify,
  mdiPlus,
  mdiHelp,
  mdiPlaneCar,
  mdiMapSearch,
  mdiCar,
  mdiEye,
} from "@mdi/js";

const {
  required,
  requiredIf,
  minLength,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  components: {
    HeaderLeft,
    HeaderRight,
    HistoryDetail,
    ReferenceDetail,
    peerSelector,
    insertComment,
    insertPassphrase,
    froalaEditor,
    "file-upload": VueUploadComponent,
    VueHtml2pdf,
    MemoPrintPreview,
    memoValidationModal,
  },
  data() {
    return {
      //penambahan SPPD
      jenisPerjalanan: [
        { id: 1, value: "dalam_wilayah", title: "Dalam Wilayah" },
        { id: 2, value: "luar_wilayah", title: "Luar Wilayah" },
        { id: 3, value: "luar_negeri", title: "Luar Negeri" },
      ],
      transportDiTujuan: [
        { id: 1, value: "rental", title: "Rental" },
        { id: 2, value: "online", title: "Online" },
        { id: 3, value: "tidak_ada", title: "Tidak Ada" },
        { id: 4, value: "pribadi", title: "Kendaraan Pribadi" },
      ],
      gradeList: ["BOD", "BOD-1", "BOD-2", "BOD-3", "BOD-4", "BOD-5", "NN"],
      statusKaryawan: [
        "DEKOM",
        "IKBI",
        "Komite/Sekdekom",
        "Konsultan",
        "Magang",
        "PKWT/OS",
        "Tamu Perusahaan",
      ],
      transportList: [
        "Pesawat Terbang (Company Paid)",
        "Kereta Api (Company Paid)",
        "Bus (Company Paid)",
        "Kapal Laut (Company Paid)",
        "Mobil (Company Paid)",
        "Booking Mandiri (Reimburse)",
        "Kendaraan Pribadi (Reimburse)",
      ],
      karyawanLoading: false,
      bagianLoading: false,
      detailLoading: false,
      rombonganLoading: false,
      lokasiLoading: false,
      qSearchKaryawan: "",
      qSearchLokasi: null,
      lastQRombongan: "",
      daftarKaryawan: [],
      listBagian: [],
      entriesLokasi: [],
      selectedPic: null,
      //END OF SPPD
      iconAttachment: mdiAttachment,
      iconUrgent: mdiClockFast,
      iconBom: mdiStarCircle,
      iconHistory: mdiHistory,
      iconEdit: mdiPen,
      iconSecret: mdiIncognito,
      iconPermit: mdiBriefcaseCheck,
      iconSave: mdiContentSaveOutline,
      iconCog: mdiCog,
      iconDelete: mdiDeleteAlertOutline,
      iconDownload: mdiCloudDownloadOutline,
      iconMailOpen: mdiEmailOpenOutline,
      iconClose: mdiClose,
      iconSend: mdiSend,
      iconQuestion: mdiHelpCircle,
      iconRevoke: mdiStepBackward2,
      iconInbox: mdiEmailArrowRightOutline,
      iconDuplicate: mdiContentDuplicate,
      iconSubmit: mdiElevatorUp,
      iconZip: mdiZipBoxOutline,
      iconMenu: mdiMenu,
      iconTgl: mdiCalendar,
      iconEye: mdiEye,
      iconUser: mdiAccount,
      iconCheck: mdiCheck,
      iconSearch: mdiMagnify,
      iconAdd: mdiPlus,
      iconHelp: mdiHelp,
      iconPlaneCar: mdiPlaneCar,
      iconMapSearch: mdiMapSearch,
      iconCar: mdiCar,

      myTab: 0,
      timeout: null,
      comment: null,
      showPeerDialog: false,
      showCommentDialog: false,
      passPhrase: null,
      showPassPhraseDialog: false,
      editorDisabled: false,
      attachments: undefined,
      currAttachments: [],
      selectedPeers: undefined,
      hal: null,
      isi: null,
      submitOptions: undefined,
      overlay: false,
      lastAction: null,
      serverAttachment: null,
      sendiri: false,
      axiosProcessing: false,
      form: {
        jenis_perjalanan: "",
        tujuan_dinas_detail: null,
        rombongan_organik: false,
        rombongan_non_organik: false,
        selected_pic: null,
        nama_provinsi: null,
        keperluan_dinas: "",
        transport: null,
        transport_lokasi_dinas: null,
        tanggal_kembali: null,
        tanggal_berangkat: null,
        attachment: null,
        bagian_inisiator: null,
      },
      selectedLokasi: null,
      karyawanDivisi: [],
      karyawanRombongan: [],
      rombonganOrganiks: [
        { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ],
      rombonganNonOrganiks: [
        { nama: null, grade: null, status_karyawan: null }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ],
      menuTglBerangkat: false,
      menuTglKembali: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      jenis_perjalanan: { required },
      tujuan_dinas_detail: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(100),
      },
      selected_pic: {
        required: requiredIf(function () {
          return !this.sendiri;
        }),
      },
      nama_provinsi: {
        required: requiredIf(function () {
          return this.form.jenis_perjalanan !== "luar_negeri";
        }),
      },
      keperluan_dinas: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(200),
      },
      transport: { required },
      transport_lokasi_dinas: { required },
      tanggal_kembali: { required },
      tanggal_berangkat: { required },
      bagian_inisiator: { required },
    },
  },
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("draft", ["axiosLoading", "memoSigning", "singleRevision"]),
    ...mapFields("pdfgenerator", ["controlValue"]),
    username() {
      if (!this.currentUser) return "";
      return this.currentUser.username;
    },
    dateIsValid() {
      if (!this.form.tanggal_berangkat || !this.form.tanggal_kembali)
        return true;
      const tanggalPertama = dayjs(this.form.tanggal_berangkat);
      const tanggalKedua = dayjs(this.form.tanggal_kembali);
      return tanggalPertama.isBefore(tanggalKedua);
    },
    selectedOrgaNik() {
      // if (!this.rombonganOrganiks || !this.rombonganOrganiks.length < 1) return '';
      return this.rombonganOrganiks
        .map((item) => {
          if (item.selected && item.selected.nik_sap) {
            return item.selected.nik_sap;
          } else {
            return ""; // Atau berikan nilai default jika diperlukan, seperti 'N/A'
          }
        })
        .filter((nik) => nik)
        .join(",");
    },
    selectedNonOrgaNik() {
      return this.rombonganNonOrganiks
        .map((item) => {
          if (item && item.nama) {
            return item.nama;
          } else {
            return ""; // Atau berikan nilai default jika diperlukan, seperti 'N/A'
          }
        })
        .filter((nik) => nik)
        .join(",");
    },
    canRentACar() {
      //jangan dihapus tapi dinonaktifkan; mana tau berubah lg
      // if (this.form.transport_lokasi_dinas !== 'rental') return true;
      // if (this.sendiri) {
      //   if (!this.selectedOrgaNik && !this.selectedNonOrgaNik) return false
      //   if (this.selectedOrgaNik.length >= 7) return true
      //   if (this.selectedNonOrgaNik.length >= 5) return true
      // } else {
      //   if (!this.selectedPic) return false
      //   if (this.selectedPic.length > 0) {
      //     if (!this.selectedOrgaNik && !this.selectedNonOrgaNik) return false
      //   }
      //   if (this.selectedOrgaNik.length >= 7) return true
      //   if (this.selectedNonOrgaNik.length >= 5) return true
      // }

      return true;
    },
    itemsLokasi() {
      if (this.entriesLokasi.length == undefined) return null;
      return this.entriesLokasi.map((entry) => {
        const Description =
          entry.nama_kota.length > 80
            ? entry.nama_kota.slice(0, this.descriptionLimit) + "..."
            : entry.nama_kota;

        return Object.assign({}, entry, { Description });
      });
    },
    userIsPjpDirSekretaris() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS", "DIREKTUR", "SEKRETARIS"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
    nikIsDuplicate() {
      if (this.selectedOrgaNik === "") return false;
      if (!this.form.selected_pic) return false;
      return this.selectedOrgaNik.includes(this.form.selected_pic);
    },
    memberIsUser() {
      if (!this.sendiri) return false;
      if (this.selectedOrgaNik === "") return false;
      return this.selectedOrgaNik.includes(this.username);
    },
    memberIsDuplicate() {
      if (this.selectedOrgaNik === "") return false;
      const elements = this.selectedOrgaNik
        .split(",")
        .map((item) => item.trim());
      const uniqueElements = new Set(elements);
      return uniqueElements.size !== elements.length;
    },
    userIsPjpDir() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS", "DIREKTUR"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
    sentOrRevoked() {
      if (!this.singleRevision) return false;
      let arrStatus = [100, 19, 29, 39, 49, 499, 59, 599];
      return arrStatus.includes(
        parseInt(this.singleRevision.memo.memo_status_id)
      );
    },
    actionMenu() {
      //cek if the reviser type is OR(original reviser) or PR(peer-reviser)
      if (!this.singleRevision) return [];
      return this.singleRevision.menus;
      //revisionDetailTOBODmenu
      //revisionDetailORNoPeerMenu
    },
    currentUserType() {
      if (!this.currentUser) return undefined;
      return this.currentUser.level_aplikasi_elemen;
    },
    isSuratMasuk() {
      //if status is sent and isMasuk = true show the label;
      if (!this.singleRevision) return false;
      return (
        parseInt(this.singleRevision.memo.is_masuk) === 1 &&
        parseInt(this.singleRevision.memo.memo_status_id) === 100
      );
    },
    lembagaPengirim() {
      if (!this.singleRevision) return "";
      return this.singleRevision.memo.lembaga_pengirim_surat;
    },
    revisionStatus() {
      if (!this.singleRevision || !this.currentUserType) return "submitted";
      return this.singleRevision.memo.status_aksi;
    },
    userCanEdit() {
      if (!this.singleRevision || !this.currentUserType) return true;
      let arrDirKom = ["DIREKTUR", "KOMISARIS", "DIREKSI"];
      let arrMemoType = ["subholding", "suratx", "xnusantara"];
      if (
        arrMemoType.includes(this.singleRevision.memo.tipe) &&
        arrDirKom.includes(this.currentUserType)
      )
        return false;

      if (parseInt(this.singleRevision.memo.bisa_diubah) === 0) return false;
      if (this.revisionStatus === "pending") return true;
      return false;
    },
    htmlToPdfOptions() {
      return {
        margin: 2,
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
        filename: this.singleRevision
          ? this.strCut(this.singleRevision.memo.hal, 100) + ".pdf"
          : "undefined.pdf",
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "cm",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
    heading() {
      if (
        [
          "sent.detailmac",
          "sent.detailmar",
          "sentxnusantara.detailmar",
          "sentxnusantara.detailmac",
        ].includes(this.$route.name)
      ) {
        return this.$t("card.sent-detail-title");
      } else {
        return this.$t("card.revision-detail-title");
      }
    },
  },
  created() {
    this.reloadThis();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    singleRevision(val) {
      if (!val) return;
      if (val.memo.tipe === "sppd") return this.parseSppdDetail(val);
      this.currAttachments = val.memo_attachments ? val.memo_attachments : [];
      this.hal = val.memo.hal;
      this.isi = val.memo.isi;
      if (
        parseInt(val.memo.approver_valid) !== 1 &&
        val.memo.memo_status_id != 100
      ) {
        this.$dialog.error({
          text: this.$t("msgbox.approver-has-changed-can-only-terminate"),
          title: this.$t("msgbox.approver-has-changed"),
          persistent: true,
          actions: [{ text: this.$t("button.ok"), color: "blue", key: false }],
        });
      }
    },
    sendiri(val) {
      if (val === true) {
        this.selectedPic = null;
        this.form.selected_pic = this.username;
      }
      if (
        this.karyawanLoading ||
        this.bagianLoading ||
        this.detailLoading ||
        this.rombonganLoading ||
        this.lokasiLoading
      )
        return;
      this.rombonganOrganiks = [
        { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ];
      if (this.$store.state.editSppd === true) this.getKaryawanRombongan();
    },
    memoSigning(val) {
      if (!val) return (this.overlay = false);
    },
    selectedLokasi(val) {
      if (val) {
        this.form.nama_provinsi = val.nama_kota;
      } else {
        this.form.nama_provinsi = "";
      }
    },
    qSearchLokasi: {
      handler: debounce(function (val) {
        if (!val) return;
        this.getDaftarLokasi(val);
      }, 500),
      immediate: true,
    },
  },
  methods: {
    ...mapActions("draft", [
      "setAxiosLoading",
      "setMemoSigning",
      "terminateThisDraft",
      "loadRevisionsDetail",
      "setSingleRevision",
      "deleteServerAttachment",
      "shareMyRevision",
      "requestPjpSekperApproval",
      "setDraftHistory",
      "getDraftHistory",
      "setDraftInfo",
      "submitRevisionAsPeer",
      "submitMyRevision",
      "sendMemo",
      "sendIncomingMemo",
      "sendLetterX",
      "sendSubholding",
      "submitX",
      "submitSubholding",
      "saveOnlyRevision",
      "revokeMemo",
      "loadTerminatedDetail",
    ]),
    ...mapActions("inbox", ["setMemoValidationDialog"]),
    ...mapActions("sent", ["makeCopyAsNewDraft"]),
    reloadThis() {
      this.rombonganOrganiks = [
        {
          loading: false,
          selected: null,
          entries: [],
          search: "",
        },
      ];
      this.setSingleRevision(undefined);
      this.setDraftHistory(undefined);
      this.setDraftInfo(undefined);
      if (this.$route.name === "terminated.detailmar") {
        this.loadTerminatedDetail({ memoId: this.$route.params.id });
      } else if (
        this.$route.name === "sent.detailmar" ||
        this.$route.name === "sentx.detailmar" ||
        this.$route.name === "sentxnusantara.detailmar" ||
        this.$route.name === "mysppd.detailmar" ||
        this.$route.name === "issuedsppd.detailmar"
      ) {
        this.loadRevisionsDetail({
          memoId: this.$route.params.id,
          mode: "memo",
        });
        this.getDraftHistory({
          rowId: this.$route.params.id,
          tableName: "memo",
        });
      } else {
        this.loadRevisionsDetail({
          memoId: this.$route.params.id,
          mode: "revision",
        });
        this.getDraftHistory({
          rowId: this.$route.params.id,
          tableName: "revision",
        });
      }
    },
    async getAllKaryawanDivisi() {
      if (this.karyawanLoading === true) return;
      this.karyawanLoading = true;
      try {
        const response = await http.get(
          `src/list-karyawan?page_mode=revision&row_id=${this.$route.params.id}&must_filter=0&searchkey=&selected_pic=${
            this.selectedPic ? this.selectedPic.nik_sap : this.username
          }&selected_nik=${this.selectedOrgaNik}`
        );
        if (response && response.status == "success") {
          this.karyawanDivisi = response.data;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.karyawanLoading = false;
      }
    },
    async getKaryawanRombongan() {
      if (this.rombonganLoading === true) return;
      this.rombonganLoading = true;
      try {
        const response = await http.get(
          `src/list-karyawan?page_mode=revision&row_id=${this.$route.params.id}&must_filter=1&searchkey=&selected_pic=${this.selectedPic.nik_sap}&selected_nik=${this.selectedOrgaNik}`
        );
        if (response && response.status == "success") {
          this.karyawanRombongan = response.data;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.rombonganLoading = false;
      }
    },
    async getAllBagian() {
      if (this.bagianLoading === true) return;
      this.bagianLoading = true;
      try {
        const response = await http.get(
          `src/list-bagian?searchkey=&selected_bagian=`
        );
        if (response) {
          this.listBagian = response;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.bagianLoading = false;
      }
    },
    async getDaftarLokasi() {
      if (this.lokasiLoading) return;
      if (!this.qSearchLokasi) return;
      if (this.qSearchLokasi.length < 3) return;
      this.lokasiLoading = true;
      try {
        const response = await http.get(
          `src/list-kota-provinsi?searchkey=${this.qSearchLokasi}`
        );
        if (response && response.status == "success") {
          this.entriesLokasi = response.data;
        }
      } catch (error) {
        console.error(error);
        this.lokasiLoading = false;
      } finally {
        this.lokasiLoading = false;
      }
    },

    parseSppdDetail(val) {
      this.getAllBagian();
      // this.getKaryawanRombongan();

      this.form.jenis_perjalanan = val.memo.sppd_jenis_perjalanan;
      this.form.tujuan_dinas_detail = val.memo.sppd_tujuan_dinas_detail;
      if (val.memo.sppd_tujuan_nama_kota !== "Luar Negeri") {
        this.form.nama_provinsi = val.memo.sppd_tujuan_nama_kota;
        this.form.nama_kota = val.memo.sppd_tujuan_nama_kota;
      }
      this.form.keperluan_dinas = val.memo.sppd_keperluan_dinas;
      this.form.transport = val.memo.sppd_transport;
      this.form.transport_lokasi_dinas = val.memo.sppd_transport_lokasi_dinas;
      this.form.tanggal_kembali = val.memo.sppd_tanggal_kembali;
      this.form.tanggal_berangkat = val.memo.sppd_tanggal_berangkat;
      this.serverAttachment = val.attachment;
      this.hal = val.memo.hal;
      this.isi = val.memo.isi;
      this.form.bagian_inisiator = {
        id: val.memo.sppd_bagian_inisiator_id,
        inisial_bagian: val.memo.sppd_bagian_inisiator,
      };
      this.form.selected_pic = val.memo.sppd_detail_pic.nik_sap;
      this.selectedPic = val.memo.sppd_detail_pic;
      this.form.selected_pic = val.memo.sppd_nik_pic;

      this.getAllKaryawanDivisi();

      if (val.pesertaNonPtpn4) this.parsePesertaNonPtpn4(val.pesertaNonPtpn4);
      if (val.pesertaPtpn4) this.parsePesertaPtpn4(val.pesertaPtpn4);
      if (val.memo.detail_tujuan_kota !== "Luar Negeri") {
        this.parseKotaProvinsi(val.memo.detail_tujuan_kota);
      }
    },
    parsePesertaNonPtpn4(data) {
      if (data.length >= 1) {
        this.form.rombongan_non_organik = true;
        this.rombonganNonOrganiks = [];
        data.forEach((item) => {
          this.rombonganNonOrganiks.push(item);
        });
      }
    },
    async parsePesertaPtpn4(data) {
      await this.getKaryawanRombongan().then(() => {
        if (data.length >= 1) {
          this.form.rombongan_organik = true;
          this.rombonganOrganiks = [];
          data.forEach((item) => {
            this.rombonganOrganiks.push({
              loading: false,
              selected: item,
              entries: [],
              search: "",
            });
          });
        }
      });
    },
    async parseKotaProvinsi(selectedKota) {
      //ambil daftar kota berdasarkan nama kota yang dipilih
      if (!selectedKota || selectedKota === "Luar Negeri") return;
      this.qSearchLokasi = selectedKota.nama_kota;
      await this.getDaftarLokasi();
      this.$nextTick(() => {
        this.selectedLokasi = selectedKota;
      }, 500);
    },
    getStatus(params) {
      if (!params) return "locked";
      return this.draftStatus({
        hasRead: params.hasRead,
        memoStatus: params.memoStatus,
        revisionStatus: params.revisionStatus,
        revisionTypeId: params.revisionTypeId,
        currentUserType: this.currentUserType,
      });
    },
    showHidePeerDialog(val) {
      this.showPeerDialog = val;
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    showHidePassPhraseDialog(val) {
      this.showPassPhraseDialog = val;
    },
    peerSelected(val) {
      if (!val) return;
      this.selectedPeers = val;
      this.shareThis();
    },
    commentFilled(val) {
      if (!val) return;
      this.comment = val;
      if (this.submitOptions.mode === "terminated") this.terminateThis();
      if (this.submitOptions.mode === "revoke") this.revokeThis();
    },
    passPhraseFilled(val) {
      if (!val) return;
      this.passPhrase = val;
      if (this.lastAction === "send") return this.sendThis();
      if (this.lastAction === "sendx") return this.sendThisX();
      if (this.lastAction === "send_subholding")
        return this.sendThisSubholding();
      if (this.lastAction === "submitx") return this.submitXLetter();
      if (this.lastAction === "submit_subholding")
        return this.submitMemoSubholding();
    },
    changeEditorData(val) {
      this.isi = val;
    },
    froalaIsWorking() {},
    menuAction(params) {
      console.log(params);
      this.lastAction = params.command;
      switch (params.command) {
        case "home":
          this.backToList();
          break;
        case "home_sent":
          this.$router.push({ name: "sent" });
          break;
        case "home_sentx":
          this.$router.push({ name: "sentx" });
          break;
        case "home_sentxnusantara":
          this.$router.push({ name: "sentxnusantara" });
          break;
        case "home_issuedsppd":
          this.$router.push({ name: "issuedsppd" });
          break;
        case "home_mysppd":
          this.$router.push({ name: "mysppd" });
          break;
        case "reload":
          this.reloadThis();
          break;
        case "save":
        case "save_only":
        case "save_only_approver":
          this.saveThis(params.command);
          break;
        case "share_to_revision":
          this.shareThis();
          break;
        case "ask_pjp_sekper_approval":
          this.askPjpSekper();
          break;
        case "submit":
        case "submit_to_upline":
          this.submitThis();
          break;
        case "submitx":
          this.submitXLetter();
          break;
        case "submit_subholding":
          this.submitMemoSubholding();
          break;
        case "send":
          this.sendThis();
          break;
        case "sendx":
          this.sendThisX();
          break;
        case "send_subholding":
          this.sendThisSubholding();
          break;
        case "send_incoming":
          this.sendIcoming();
          break;
        case "submit_as_peer":
          this.submitThisAsPeer();
          break;
        case "terminate_draft":
          this.terminateThis();
          break;
        case "validate":
          this.setMemoValidationDialog(true);
          break;
        case "revoke":
          this.revokeThis();
          break;
        case "print_preview":
          this.updateOverlay(true);
          setTimeout(() => {
            this.downloadPdf();
          }, 200);
          break;
        case "share_link":
          this.$copyText(
            `https://elemen.ptpn.id/link/${this.singleRevision.memo.id_hash}`
          ).then(() => {
            this.$dialog.notify.info(
              this.$t("msgbox.you-just-copied-the-memo-message-to-clipboard"),
              {
                position: "top-left",
                timeout: 5000,
              }
            );
          });
          break;
        default:
          break;
      }
    },
    async submitSppd(mode) {
      if (this.axiosProcessing === true) return;
      if (!mode) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (
        this.nikIsDuplicate ||
        this.memberIsDuplicate ||
        this.memberIsUser ||
        !this.dateIsValid ||
        !this.canRentACar
      )
        return;
      if (this.form.rombongan_organik) {
        if (!this.selectedOrgaNik || this.selectedOrgaNik.length < 1) {
          return this.$dialog.error({
            text: this.$t(
              "msgbox.you checked the organic group but did not fill in the participants"
            ),
            title: this.$t("msgbox.error"),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });
        }
      }
      if (this.form.rombongan_non_organik) {
        if (!this.selectedNonOrgaNik || this.selectedNonOrgaNik.length < 1) {
          return this.$dialog.error({
            text: this.$t(
              "msgbox.you checked the non organic group but did not fill in the participants"
            ),
            title: this.$t("msgbox.error"),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });
        }
        if (!this.checkForNullValues(this.rombonganNonOrganiks)) {
          return this.$dialog.error({
            text: this.$t(
              "msgbox.please complete all non-organic participant data items"
            ),
            title: this.$t("msgbox.error"),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });
        }
      }

      this.axiosProcessing = true;
      let formData = new FormData();
      formData.append("memo_id", this.singleRevision.memo.memo_id_hash);
      formData.append("jenis_perjalanan", this.form.jenis_perjalanan); //luar wilayah/dalam wilayah/luar negeri
      formData.append("nik_pic", this.form.selected_pic); //jika bukan username sebagai PIC
      formData.append("nama_pic", this.selectedPic.nama); //username sebagai default PIC
      if (this.form.jenis_perjalanan !== "luar_negeri") {
        formData.append("tujuan_kota_id", this.selectedLokasi.cid);
        formData.append("tujuan_nama_kota", this.form.nama_kota);
      }
      formData.append("tujuan_dinas", this.form.nama_provinsi);
      formData.append("tujuan_dinas_detail", this.form.tujuan_dinas_detail);
      formData.append("keperluan_dinas", this.form.keperluan_dinas);
      formData.append("transport", this.form.transport);
      formData.append("tanggal_berangkat", this.form.tanggal_berangkat);
      formData.append("tanggal_kembali", this.form.tanggal_kembali);
      formData.append(
        "transport_lokasi_dinas",
        this.form.transport_lokasi_dinas
      );
      formData.append(
        "bagian_inisiator",
        this.form.bagian_inisiator.inisial_bagian
      );
      formData.append("bagian_inisiator_id", this.form.bagian_inisiator.id);
      formData.append("action", mode);

      if (this.selectedOrgaNik)
        formData.append("rombongan_organik", this.selectedOrgaNik);
      if (this.selectedNonOrgaNik) {
        this.rombonganNonOrganiks.forEach((item, index) => {
          formData.append(`rombongan_non_organik[${index}][nama]`, item.nama);
          formData.append(`rombongan_non_organik[${index}][grade]`, item.grade);
          formData.append(
            `rombongan_non_organik[${index}][status_karyawan]`,
            item.status_karyawan
          );
        });
      }

      if (mode === "share_to_revision") {
        let strNik = this.selectedPeers.map(({ nik }) => nik);
        formData.append("reviser_nik", strNik.join());
      }

      if (this.form.attachment)
        formData.append("attachment", this.form.attachment);
      //getCurrentIdhashForUpdate
      let currHashid = this.$route.params.id;
      if (currHashid === "") {
        return this.$dialog.error({
          text: this.$t(
            "msgbox.failed to submit sppd draft due to empty record id"
          ),
          title: this.$t("msgbox.submit-failed"),
          persistent: true,
          actions: [
            {
              text: this.$t("button.ok"),
              color: "success",
              key: false,
              icon: mdiCheck,
            },
          ],
        });
      }

      try {
        const response = await http.post(
          `sppd/revision/${currHashid}`,
          formData
        );
        if (response && response.status == "success") {
          let title = "msgbox.save success";
          let message = "msgbox.submit sppd revision to upline is success";
          if (mode === "save") {
            message = "msgbox.save sppd revision is success";
            title = "msgbox.submit-success";
          }
          if (mode === "share_to_revision") {
            message = "msgbox.share sppd revision is success";
            title = "msgbox.submit-success";
          }
          this.$dialog.info({
            text: this.$t(message),
            title: this.$t(title),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });

          return this.backToList();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.axiosProcessing = false;
        this.getAllKaryawanDivisi();
      }
    },
    saveThis(action) {
      //update save as draft for me

      if (this.singleRevision.memo.tipe === "sppd")
        return this.submitSppd("save");

      let formData = new FormData();

      formData.append("hal", this.hal);
      formData.append("isi", this.isi);
      formData.append("action", action);
      formData.append("revision_status", 1);

      if (this.attachments && this.attachments.length > 0) {
        for (const [key, file] of Object.entries(this.attachments)) {
          formData.append(`attachments[${key}]`, file.file);
        }
      }

      this.saveOnlyRevision({
        memoId: this.singleRevision.memo.memo_id_hash,
        revisionId: this.singleRevision.memo.id_hash,
        formData: formData,
      });

      this.$nextTick(() => {
        this.backToList();
      });
    },

    mapId(obj) {
      if (!obj || obj.length === 0) return [];
      return obj.map(({ id }) => id);
    },
    shareThis() {
      //open peer selector
      if (parseInt(this.singleRevision.memo.can_share_to_revise) !== 1) {
        return this.$dialog.notify.error(
          this.$t("error.cannot share final revisi"),
          {
            position: "top-left",
            timeout: 5000,
          }
        );
      }
      if (!this.selectedPeers || this.selectedPeers.length < 1)
        return this.showHidePeerDialog(true);
      //  'reviser_nik' => 'required|string', //comma delimiter
      // 'hal' => 'nullable|string',
      // 'isi' => 'nullable|string',

      // console.log('share to peers');
      let strNik = this.selectedPeers.map(({ nik }) => nik);

      if (this.singleRevision.memo.tipe === "sppd")
        return this.submitSppd("share_to_revision");

      let formData = new FormData();

      formData.append("reviser_nik", strNik.join());
      formData.append("hal", this.hal);
      formData.append("isi", this.isi);
      formData.append("revision_status", 1);

      if (this.attachments && this.attachments.length > 0) {
        for (const [key, file] of Object.entries(this.attachments)) {
          formData.append(`attachments[${key}]`, file.file);
        }
      }

      this.shareMyRevision({
        memoId: this.singleRevision.memo.id_hash,
        formData: formData,
      });

      this.$nextTick(() => {
        this.backToList();
      });
    },
    askPjpSekper() {
      if (parseInt(this.singleRevision.memo.can_share_to_revise) !== 1) {
        return this.$dialog.notify.error(
          this.$t("error.cannot share final revisi"),
          {
            position: "top-left",
            timeout: 5000,
          }
        );
      }
      this.$dialog
        .confirm({
          text: this.$t(
            "msgbox.are you sure to submit this revision to pjp sekper"
          ),
          title: this.$t("msgbox.submit-revision"),
          persistent: true,
          icon: this.iconQuestion,
          color: "warning",
          actions: [
            { text: this.$t("button.cancel"), color: "red", key: false },
            { text: this.$t("button.yes-submit"), color: "primary", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = new FormData();
          formData.append("hal", this.hal);
          formData.append("isi", this.isi);
          formData.append("reviser_nik", "99999999999");
          formData.append("revision_status", 1);

          if (this.attachments && this.attachments.length > 0) {
            for (const [key, file] of Object.entries(this.attachments)) {
              formData.append(`attachments[${key}]`, file.file);
            }
          }

          this.requestPjpSekperApproval({
            memoId: this.singleRevision.memo.id_hash,
            formData: formData,
          });
          this.$nextTick(() => {
            this.backToList();
          });
        });
    },
    submitThisAsPeer() {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-submit-this-revision"),
          title: this.$t("msgbox.submit-revision"),
          persistent: true,
          icon: this.iconQuestion,
          color: "warning",
          actions: [
            { text: this.$t("button.cancel"), color: "red", key: false },
            { text: this.$t("button.yes-submit"), color: "primary", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = new FormData();

          formData.append("hal", this.hal);
          formData.append("isi", this.isi);

          if (this.singleRevision.memo.tipe === "sppd") {
            formData.append(
              "isi",
              `Pengajuan SPPD Atas Nama : ${this.selectedPic.nama}, Tujuan : ${this.form.tujuan_dinas_detail}`
            );
            formData.append(
              "hal",
              `Pengajuan SPPD Atas Nama : ${this.selectedPic.nama}, Tujuan : ${this.form.tujuan_dinas_detail}`
            );
          }

          formData.append("revision_status", 1);

          if (this.attachments && this.attachments.length > 0) {
            for (const [key, file] of Object.entries(this.attachments)) {
              formData.append(`attachments[${key}]`, file.file);
            }
          }

          this.submitRevisionAsPeer({
            memoId: this.singleRevision.memo.memo_id_hash,
            revisionId: this.singleRevision.memo.id_hash,
            formData: formData,
          });

          this.$nextTick(() => {
            this.backToList();
          });
        });
    },
    submitXLetter() {
      if (!this.passPhrase) {
        this.submitOptions = {
          mode: "submit",
          btnText: "button.process",
          btnIcon: this.iconSubmit,
          commentDesc: this.$t(
            "text.please-input-your-digital-signature-passphrase-for-submit-x"
          ),
        };
        return (this.showPassPhraseDialog = true);
      }

      this.updateOverlay(true);

      let formData = new FormData();

      formData.append("revision_status", 1);
      formData.append("passphrase", this.passPhrase);
      formData.append("hal", this.hal);
      formData.append("isi", this.isi);

      if (this.attachments && this.attachments.length > 0) {
        for (const [key, file] of Object.entries(this.attachments)) {
          formData.append(`attachments[${key}]`, file.file);
        }
      }

      this.submitX({
        memoId: this.singleRevision.memo.id_hash,
        formData: formData,
      });

      this.lastAction = null;
      this.passPhrase = null;
    },
    submitMemoSubholding() {
      if (!this.passPhrase) {
        this.submitOptions = {
          mode: "submit",
          btnText: "button.process",
          btnIcon: this.iconSubmit,
          commentDesc: this.$t(
            "text.please-input-your-digital-signature-passphrase-for-submit-subholding"
          ),
        };
        return (this.showPassPhraseDialog = true);
      }

      this.updateOverlay(true);

      let formData = new FormData();

      formData.append("revision_status", 1);
      formData.append("passphrase", this.passPhrase);
      formData.append("hal", this.hal);
      formData.append("isi", this.isi);

      if (this.attachments && this.attachments.length > 0) {
        for (const [key, file] of Object.entries(this.attachments)) {
          formData.append(`attachments[${key}]`, file.file);
        }
      }

      this.lastAction = null;
      this.passPhrase = null;

      this.submitSubholding({
        memoId: this.singleRevision.memo.id_hash,
        formData: formData,
      });
    },
    submitThis() {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-submit-this-revision"),
          title: this.$t("msgbox.submit-revision"),
          persistent: true,
          icon: this.iconQuestion,
          color: "warning",
          actions: [
            { text: this.$t("button.cancel"), color: "red", key: false },
            { text: this.$t("button.yes-submit"), color: "primary", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          if (this.singleRevision.memo.tipe === "sppd")
            return this.submitSppd("submit");

          let formData = new FormData();

          formData.append("hal", this.hal);
          formData.append("isi", this.isi);
          formData.append("revision_status", 1);

          if (this.attachments && this.attachments.length > 0) {
            for (const [key, file] of Object.entries(this.attachments)) {
              formData.append(`attachments[${key}]`, file.file);
            }
          }

          this.submitMyRevision({
            memoId: this.singleRevision.memo.id_hash,
            formData: formData,
          });

          this.$nextTick(() => {
            this.backToList();
          });
        });
    },
    sendThis() {
      if (!this.passPhrase) {
        this.submitOptions = {
          mode: "send",
          btnText: "button.process",
          btnIcon: this.iconSend,
          commentDesc: this.$t(
            "text.please-input-your-digital-signature-passphrase"
          ),
        };
        return (this.showPassPhraseDialog = true);
      }

      this.updateOverlay(true);

      let formData = new FormData();
      formData.append("passphrase", this.passPhrase);
      formData.append("isi", this.isi);
      formData.append("hal", this.hal);

      if (this.singleRevision.memo.tipe === "sppd") {
        formData.append(
          "isi",
          `Pengajuan SPPD Atas Nama : ${this.selectedPic.nama}, Tujuan : ${this.form.tujuan_dinas_detail}`
        );
        formData.append(
          "hal",
          `Pengajuan SPPD Atas Nama : ${this.selectedPic.nama}, Tujuan : ${this.form.tujuan_dinas_detail}`
        );
      }

      this.sendMemo({
        memoId: this.singleRevision.memo.id_hash,
        formData: formData,
      });

      this.lastAction = null;
      this.passPhrase = null;

      // this.$nextTick(() => {
      //   if (!this.memoSigning) {
      //     this.$router.replace({ name: "approval" });
      //   }
      // });
    },
    sendThisX() {
      if (!this.passPhrase) {
        this.submitOptions = {
          mode: "send",
          btnText: "button.process",
          btnIcon: this.iconSend,
          commentDesc: this.$t(
            "text.please-input-your-digital-signature-passphrase-to-send-x"
          ),
        };
        return (this.showPassPhraseDialog = true);
      }

      this.updateOverlay(true);

      let formData = new FormData();
      formData.append("passphrase", this.passPhrase);
      formData.append("isi", this.isi);
      formData.append("hal", this.hal);

      this.sendLetterX({
        memoId: this.singleRevision.memo.id_hash,
        formData: formData,
      });

      this.lastAction = null;
      this.passPhrase = null;
    },
    sendThisSubholding() {
      if (!this.passPhrase) {
        this.submitOptions = {
          mode: "send",
          btnText: "button.process",
          btnIcon: this.iconSend,
          commentDesc: this.$t(
            "text.please-input-your-digital-signature-passphrase-to-send-subholding"
          ),
        };
        return (this.showPassPhraseDialog = true);
      }

      this.updateOverlay(true);

      let formData = new FormData();
      formData.append("passphrase", this.passPhrase);
      formData.append("isi", this.isi);
      formData.append("hal", this.hal);

      this.sendSubholding({
        memoId: this.singleRevision.memo.id_hash,
        formData: formData,
      });

      this.lastAction = null;
      this.passPhrase = null;
    },
    terminateThis() {
      // return   this.$dialog.error({
      //     text: this.$t("msgbox.cannot-terminate-more-than-one-draft-at-a-time"),
      //     title: this.$t("msgbox.terminate-draft"),
      //     persistent: true,
      //   });

      if (!this.comment) {
        this.submitOptions = {
          mode: "terminated",
          btnText: "button.terminate",
          btnIcon: this.iconDelete,
          commentDesc: this.$t(
            "text.please-fill-termination-comment-or-reason"
          ),
        };
        return (this.showCommentDialog = true);
      }

      let formData = {
        params: this.singleRevision.memo.id_hash,
        comment: this.comment,
      };
      this.terminateThisDraft({
        revisionId: this.singleRevision.memo.id_hash,
        formData: formData,
      });
      this.$nextTick(() => {
        this.backToList();
      });
    },
    revokeThis() {
      if (!this.comment) {
        this.submitOptions = {
          mode: "revoke",
          btnText: "button.revoke",
          btnIcon: this.iconRevoke,
          commentDesc: this.$t("text.please-fill-revoke-comment-or-reason"),
        };
        return (this.showCommentDialog = true);
      }
      let formData = {
        reason: this.comment,
      };

      this.revokeMemo({
        memoId: this.singleRevision.memo.memo_id_hash,
        formData: formData,
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.$router.replace({ name: "sent" });
        }, 500);
      });
    },
    sendIcoming() {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-send-this-memo"),
          title: this.$t("msgbox.send-memo"),
          persistent: true,
          icon: this.iconQuestion,
          color: "warning",
          actions: [
            { text: this.$t("button.cancel"), color: "red", key: false },
            { text: this.$t("button.yes-send"), color: "primary", key: true },
          ],
        })
        .then((result) => {
          if (result !== true) return;

          let formData = new FormData();

          formData.append("hal", this.hal);
          formData.append("isi", this.isi);

          if (this.singleRevision.memo.tipe === "sppd") {
            formData.append(
              "isi",
              `Pengajuan SPPD Atas Nama : ${this.selectedPic.nama}, Tujuan : ${this.form.tujuan_dinas_detail}`
            );
            formData.append(
              "hal",
              `Pengajuan SPPD Atas Nama : ${this.selectedPic.nama}, Tujuan : ${this.form.tujuan_dinas_detail}`
            );
          }
          formData.append("revision_status", 1);

          if (this.attachments && this.attachments.length > 0) {
            for (const [key, file] of Object.entries(this.attachments)) {
              formData.append(`attachments[${key}]`, file.file);
            }
          }
          this.sendIncomingMemo({
            memoId: this.singleRevision.memo.id_hash,
            formData: formData,
          });

          this.$nextTick(() => {
            this.backToList();
          });
        });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (
          !/\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx|ppt|pptx|zip|rar)$/i.test(
            newFile.name
          )
        ) {
          this.$dialog.error({
            text: this.$t("msgbox.only-accept-these-file-extension"),
            title: this.$t("msgbox.add-attachments"),
            persistent: true,
            actions: [
              { text: this.$t("button.ok"), color: "blue", key: false },
            ],
          });
          return prevent();
        }

        if (newFile.file.size > 10000000) {
          this.setMessageBox({
            snackbar: false,
            show: true,
            type: "error",
            title: "error.error-title",
            message: "error.attachment-size-is-too-big",
          });

          return prevent();
        }
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        // console.log("add", newFile);
        if (this.attachments.length === 1) {
          /* this.setMessageBox({
            snackbar: true,
            show: true,
            type: "info",
            title: "msgbox.info-title",
            message: "msgbox.attachment-will-upload-on-submit-or-save",
          }); */

          return this.$dialog.notify.success(
            this.$t("msgbox.attachment-will-upload-on-submit-or-save"),
            {
              position: "top-left",
              timeout: 5000,
            }
          );
        }
      }
      if (newFile && oldFile) {
        // update
        console.info("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        //send to backend to remove by file_id
        console.info("delete", oldFile);
        //this.deleteAttachment(oldFile);
      }
    },
    confirmDeleteAttachment() {
      if (!this.serverAttachment) return;

      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-this-attachment"),
          title: this.$t("msgbox.delete-attachment"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = {
            memoId: this.serverAttachment.memo_id_hash,
            id_hash: this.serverAttachment.id_hash,
            _method: "DELETE",
          };
          this.deleteServerAttachment(formData).then((result) => {
            if (result.data == true) {
              this.serverAttachment = null;
            }
          });
        });
    },
    deleteAttachment(file) {
      if (!file) return;
      this.$refs.upload.remove(file);
    },
    downloadAttachment(mode, params) {
      if (!params) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);

      http
        .get(`/memo/${params.memo_id}/attachments/${params.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          let arrImageType = ["image/jpeg", "image/png"];
          if (response) {
            if (mode === "download") {
              let dfileURL = window.URL.createObjectURL(new Blob([response]));
              let dfileLink = document.createElement("a");

              dfileLink.href = dfileURL;
              dfileLink.setAttribute("download", params.file_name);
              document.body.appendChild(dfileLink);

              this.updateOverlay(false);
              dfileLink.click();
            } else {
              if (arrImageType.includes(params.file_type)) {
                let file = new File([response], "Download.png", {
                  type: "image/png",
                });
                var fileURL = URL.createObjectURL(file);
                let newWindow = window.open();
                newWindow.document.write(
                  `<img src="${fileURL}" alt="Downloaded Image"/>`
                );
              } else if (params.file_type === "application/pdf") {
                var file = new Blob([response], {
                  type: "application/pdf;base64",
                });
                fileURL = URL.createObjectURL(file);
                window.open(fileURL);
              }
            }
            this.updateOverlay(false);
          }
        });
    },
    async downloadAllAsZip() {
      const options = {
        responseType: "blob",
      };

      this.setAxiosLoading(true);

      http
        .get(
          `/memo/${this.singleRevision.memo.memo_id_hash}/attachments-all-as-zip`,
          options
        )
        .then((response) => {
          if (response.status === 500) return;
          if (response) {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              this.strCut(this.singleRevision.memo.hal, 30) + ".zip"
            );
            document.body.appendChild(fileLink);

            this.setAxiosLoading(false);
            fileLink.click();
          }
        });
    },
    backToList() {
      const isEksternal = parseInt(this.singleRevision.memo.is_eksternal);
      let routerName = "sent";

      switch (isEksternal) {
        case 1:
          switch (this.$route.name) {
            case "sent.detailmar":
            case "sent.detailmac":
              routerName = "sentx";
              break;
            default:
              if (
                [
                  "PJP",
                  "PJP_KANPUS",
                  "DIREKTUR",
                  "SEKRETARIS",
                  "SEKRETARIS_DIRSEVP",
                ].includes(this.currentUserType)
              ) {
                routerName = "approval";
              } else {
                routerName = "revision";
              }
              break;
          }
          break;
        default:
          switch (this.$route.name) {
            case "sent.detailmar":
            case "sent.detailmac":
              routerName = "sentx";
              break;
            default:
              if (
                [
                  "PJP",
                  "PJP_KANPUS",
                  "DIREKTUR",
                  "SEKRETARIS",
                  "SEKRETARIS_DIRSEVP",
                ].includes(this.currentUserType)
              ) {
                routerName = "approval";
              } else {
                routerName = "revision";
              }
              break;
          }
          break;
      }
      setTimeout(() => this.$router.replace({ name: routerName }), 300);
    },
    //below is function for PdfPrintPreview
    async downloadPdf() {
      window.scrollTo(0, 0);
      this.updateOverlay(true);
      try {
        const response = await http.get(
          `/memo/myrevision/${this.singleRevision.memo.memo_id_hash}/getpdf`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            responseType: "blob",
          }
        );
        var file = new Blob([response], { type: "application/pdf" });
        var url = URL.createObjectURL(file);
        window.open(url, "", "width=800,height=768");
      } catch (error) {
        console.error(error);
        this.updateOverlay(false);
      } finally {
        this.updateOverlay(false);
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert("pdf-quality value should only be 0 - 2");
        this.controlValue.pdfQuality = 2;
        return false;
      }
      if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;
        return false;
      }
      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];
      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = "a4";
        return false;
      }
      if (!this.controlValue.pdfOrientation) {
        alert("pdf-orientation value cannot be empty");
        this.controlValue.pdfOrientation = "portrait";
        return false;
      }
      if (!this.controlValue.pdfContentWidth) {
        alert("pdf-content-width value cannot be empty");
        this.controlValue.pdfContentWidth = "800px";
        return false;
      }
      return true;
    },
    onProgress(progress) {
      this.progress = progress;
      console.info(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      // console.log(`On Before PDF Generation`, html2pdf, options, pdfContent);
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },
    hasDownloaded() {
      this.pdfDownloaded = true;
      this.updateOverlay(false);
    },
    domRendered() {
      this.contentRendered = true;
    },
    onBlobGenerate() {},
    updateOverlay(val) {
      this.overlay = val;
    },
    copyAsNewDraft() {
      this.makeCopyAsNewDraft({ memoId: this.$route.params.id });
    },
    addRombongan(tipe) {
      if (tipe === "non_organik") {
        this.rombonganNonOrganiks.push({
          nama: null,
          grade: null,
          status_karyawan: null,
        }); // Tambah objek baru
      } else {
        this.rombonganOrganiks.push({
          loading: false,
          selected: null,
          entries: [],
          search: "",
        }); // Tambah objek baru
      }
    },
    removeRombongan(tipe, index) {
      if (tipe === "non_organik") {
        if (this.rombonganNonOrganiks.length > 1) {
          this.rombonganNonOrganiks.splice(index, 1); // Hapus objek berdasarkan indeks
        } else {
          this.form.rombongan_non_organik = null;
        }
      } else {
        if (this.rombonganOrganiks.length > 1) {
          this.rombonganOrganiks.splice(index, 1); // Hapus objek berdasarkan indeks
        } else {
          this.form.rombongan_organik = null;
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.auto--width {
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .auto--width {
    width: 95%;
  }
}
@media screen and (min-width: 1025px) {
  .auto--width {
    width: 80%;
  }
}
</style>
